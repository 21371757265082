import { DataGraphcms } from '@klappir/types';
import { H2, Link } from '@klappir/ui/text';
import { media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const BlockHeadingWithLinkSection = styled.section`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  ${media.xl.below`padding-top:50px; padding-bottom:50px;`};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${rem(8)}; // Adds space between "Find a" and "Partner"
  margin-bottom: ${rem(16)};
`;

const Title = styled(H2)`
  width: 60%;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  ${media.sm.below`width:90%;`};
`;

const ColoredText = styled.span`
  color: ${({ theme }) => theme.button.primary.default.primaryColor};
`;

const Subtitle = styled.div`
  font-size: ${rem(28)};
  font-weight: 400;
  width: 60%;
  padding: 20px 0 0 0;
  text-align: center;
  margin: 0 auto;
  ${media.sm.below`width:90%;`};
  ${media.mobile.below`
    font-size: ${rem(18)};
    line-height: 1.5;
    padding: 10px 0 0 0;
  `}
`;

const Label = styled.span`
  font-weight: 400;
`;

export const BlockHeadingWithLink = (
  props: DataGraphcms.BlockHeadingWithLink
) => {
  const { heading, link } = props;

  return (
    <BlockHeadingWithLinkSection>
      <TitleContainer>
        <Title>
          {heading.title} <ColoredText>{heading.coloredPart}</ColoredText>
        </Title>
      </TitleContainer>
      {heading.subtitle && (
        <>
          <Subtitle>{heading.subtitle.title}</Subtitle>
          <Subtitle>
            {heading.subtitle.subtitle.title}{' '}
            <Link href={link.link}>
              <Label>{link.label}</Label>
            </Link>
          </Subtitle>
        </>
      )}
    </BlockHeadingWithLinkSection>
  );
};
