import { rem } from 'polished';
import styled from 'styled-components';

import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { KlappirButton } from '@klappir/ui/button';
import { ResponsiveTable } from '@klappir/ui/responsive-table';
import { fonts } from '@klappir/util/styles';

const StyledContainer = styled.div`
  width: 100%;
  margin-bottom: ${rem(150)};
`;

const StyledTableContainer = styled.div<{
  alignItems?: 'center' | 'start';
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: start;
  gap: ${rem(6)};
  padding: ${({ padding }) => padding || '0'};
  height: 100%;
  min-height: inherit;
`;

const StyledTableHeader = styled.h4`
  font-size: ${rem(21)};
  font-weight: ${fonts.weight.bold};
  margin: 0;
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;

const StyledTableHeaderSecondary = styled.span`
  font-size: ${rem(14)};
  font-weight: ${fonts.weight.bold};
  display: block;
  margin-top: -${rem(11)};
`;

const StyledTableSubtitle = styled.p`
  font-size: ${rem(12)};
  font-weight: ${fonts.weight.regular};
  text-align: center;
  line-height: ${rem(18)};
  margin: 0;
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  padding: 0 0 ${rem(32)};
  flex: 1;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  align-items: start;
`;

const StyledListItem = styled.li`
  font-size: ${rem(14)};
  font-weight: bold;
  margin: 0;
  line-height: ${rem(20)};
  color: black;
`;

const StyledListBullet = styled.span<{ isTitle?: boolean }>`
  font-size: ${rem(14)};
  font-weight: ${fonts.weight.regular};
  margin-left: ${({ isTitle }) => (isTitle ? rem(0) : rem(20))};
  line-height: ${rem(20)};
  position: relative;
  padding-left: ${rem(12)};
  color: ${getColor('black')};

  &:before {
    content: ${({ isTitle }) => (isTitle ? "'│'" : "'•'")};
    position: absolute;
    left: 0;
    color: ${({ isTitle }) =>
      isTitle ? getColor('green', 100, 100) : 'black'};
    font-weight: ${({ isTitle }) => (isTitle ? 600 : 'normal')};
    transform: ${({ isTitle }) => (isTitle ? 'scaleY(0.7)' : 'none')};
  }
`;

const StyledBulletList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${rem(6)};
`;

const StyledListTitle = styled.h4<{
  color?: string;
  fontWeight?: string;
  fontSize?: string;
}>`
  font-size: ${({ fontSize }) => fontSize || rem(14)};
  font-weight: ${({ fontWeight }) => fontWeight || fonts.weight.bold};
  margin: 0;
  line-height: ${rem(20)};
  color: ${({ color }) => color || getColor('green', 100, 90)};
  position: relative;
`;

const StyledPlainText = styled.span`
  font-size: ${rem(14)};
  font-weight: ${fonts.weight.regular};
  line-height: ${rem(20)};
  color: ${getColor('black')};
`;

const StyledTableBodyHeaderText = styled.h4`
  font-size: ${rem(14)};
  font-weight: ${fonts.weight.regular};
  margin: 0;
  line-height: ${rem(20)};
`;

const StyledTableBodyPrice = styled.h4`
  font-size: ${rem(41)};
  font-weight: ${fonts.weight.bold};
  margin: 0;
`;

export const BlockPricingTable = (props: DataGraphcms.BlockPricingTable) => {
  const pricingPlans = props?.pricingPlans;

  if (!pricingPlans?.length) return null;

  const tableHeaders = pricingPlans.map((plan) => {
    const [mainTitle, secondaryTitle] = plan.title.split(/[()]/);

    return (
      <StyledTableContainer alignItems="center">
        <StyledTableHeader>{mainTitle.trim()}</StyledTableHeader>
        {secondaryTitle && (
          <StyledTableHeaderSecondary>
            {secondaryTitle.trim()}
          </StyledTableHeaderSecondary>
        )}
        <StyledTableSubtitle>{plan.subTitle}</StyledTableSubtitle>
      </StyledTableContainer>
    );
  });

  const tableBody: React.ReactNode[][] = [
    pricingPlans.map((plan) => (
      <StyledTableContainer
        alignItems="start"
        padding={`${rem(20)} ${rem(10)}`}
      >
        <StyledTableBodyHeaderText>From</StyledTableBodyHeaderText>
        <StyledTableBodyPrice>
          {plan.currency === 'EUR' ? '€' : plan.currency} {plan.planPrice}{' '}
          {plan.chargedPerUnit}
        </StyledTableBodyPrice>
      </StyledTableContainer>
    )),
    pricingPlans.map((plan) => (
      <StyledTableContainer alignItems="start" padding={rem(10)}>
        <StyledListContainer>
          {plan.featuredPlanFeatureGroups?.[0]?.title && (
            <StyledListTitle fontWeight="bold" fontSize={rem(16)}>
              {plan.featuredPlanFeatureGroups[0].title}
            </StyledListTitle>
          )}
          <StyledList>
            {plan.featuredPlanFeatureGroups?.[0]?.planFeatures?.map(
              (feature) => (
                <>
                  <StyledListItem key={feature.title}>
                    {feature.title}
                  </StyledListItem>
                  {feature.description && (
                    <StyledBulletList>
                      {feature.description.split('\n\n').map((group) => {
                        const lines = group.split('\n');
                        return lines.map((line) => {
                          const trimmedLine = line.trim();
                          if (trimmedLine.startsWith('|')) {
                            return (
                              <StyledListBullet key={trimmedLine} isTitle>
                                {trimmedLine.substring(1).trim()}
                              </StyledListBullet>
                            );
                          }
                          if (trimmedLine.startsWith('-')) {
                            return (
                              <StyledListBullet key={trimmedLine}>
                                {trimmedLine.substring(1).trim()}
                              </StyledListBullet>
                            );
                          }
                          if (trimmedLine) {
                            return (
                              <StyledPlainText key={trimmedLine}>
                                {trimmedLine}
                              </StyledPlainText>
                            );
                          }
                          return null;
                        });
                      })}
                    </StyledBulletList>
                  )}
                </>
              )
            )}
          </StyledList>
        </StyledListContainer>
        <KlappirButton
          variant="outline-transparent"
          label={plan.pricingCardButtonText}
          href={plan.pricingCardButtonLink}
        />
      </StyledTableContainer>
    )),
  ];

  return (
    <StyledContainer>
      <ResponsiveTable
        head={tableHeaders}
        body={tableBody}
        theme={{
          headerBackground: getColor('green', 100, 50),
          bodyBackground: getColor('green', 100, 10),
          stripedRowBackground: getColor('green', 100, 10),
          borderColor: getColor('green'),
          borders: {
            horizontal: { enabled: true, showOnRows: [0] },
            vertical: {
              enabled: true,
              showOnColumns: Array.from(
                { length: pricingPlans.length - 1 },
                (_, i) => i
              ),
            },
          },
        }}
      />
    </StyledContainer>
  );
};
