import styled from 'styled-components';

import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { slantedBottom } from '@klappir/ui/core';
import { media } from '@klappir/util/styles';

import Card from '../Components/Card';

const Container = styled.div<{ backgroundColor: string }>`
  margin: 0 6%;
  min-height: 385px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 10%;
  background-color: ${(props) => props?.backgroundColor ?? 'transparent'};
  ${slantedBottom(getColor('lightgreen'), 1.5)}
  ${media.mobile.below`
    margin: 0;
  `};
`;

export const BlockCardWithBackground = (
  props: DataGraphcms.BlockCardWithBackground
) => {
  const backgroundColor = getColor('lightgreen');

  const {
    title: { title, coloredPart },
    paragraph,
    link,
    button,
    textAlign,
  } = props.card;

  const { label = '', link: linkUrl = '' } = button || link || {};

  return (
    <Container backgroundColor={backgroundColor}>
      <Card
        key={title}
        title={title}
        coloredPart={coloredPart}
        paragraph={paragraph}
        label={label}
        linkButtonBackgroundColor={backgroundColor}
        textAlign={textAlign}
        {...(link && { link: linkUrl })}
        {...(button && { button: { label, link: linkUrl } })}
      />
    </Container>
  );
};
