import { easings } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

const baseCardStyles = css`
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #3d3d3d10, 0 10px 20px -10px #3d3d3d00;

  text-decoration: none;

  transform: translateY(0);
  transition: box-shadow 0.2s, transform 0.2s;
`;

export const Card = styled.div`
  ${baseCardStyles}
`;

export const CardLink = styled.a`
  ${baseCardStyles}
  &:hover {
    box-shadow: 0 0 20px -10px #3d3d3d30, 0 10px 20px -10px #3d3d3d30;
    transform: scale(1.05);
    transition: box-shadow 1s ${easings.easeOutExpo},
      transform 0.6s ${easings.easeOutExpo};
  }
`;
