import Markdown from 'markdown-to-jsx';
import React from 'react';

import { UIIcon } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { KlappirButton, KlappirButtonProps } from '@klappir/ui/button';
import { Link } from '@klappir/ui/text';
import styled from '@klappir/vendor/styled';

import { splitTitle } from '../Utils/headingHelpers';

const StyledCard = styled.div<{ textAlign?: 'center' | 'left' }>`
  padding: 0 8% 40px 0;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
`;

const Title = styled.h1`
  font-weight: 700;
`;

interface LinkButtonProps extends KlappirButtonProps {
  backgroundColor?: string;
}

const LinkButton = styled(KlappirButton)<LinkButtonProps>`
  font-weight: 600;
  padding-left: 0;
  margin: 15px 0 0 0;
  border: 0px;
  outline: transparent;
  background-color: ${(props) => props?.backgroundColor ?? 'transparent'};
  &:hover {
    background-color: ${(props) => props?.backgroundColor ?? 'transparent'};
  }
`;

const StyledMarkdown = styled(Markdown)`
  line-height: 1.5;
  h3 {
    margin-bottom: 5px;
  }
  p {
    margin-top: 5px;
  }
`;

interface CardProps {
  title: string;
  coloredPart?: string;
  paragraph?: string;
  label: string;
  link?: string;
  textAlign?: 'center' | 'left';
  button?: {
    label: string;
    link: string;
  };
  linkButtonIcon?: UIIcon.IconName;
  linkButtonBackgroundColor?: string;
}

export const Card: React.FC<CardProps> = ({
  title,
  coloredPart,
  paragraph,
  label,
  link,
  button,
  linkButtonIcon,
  linkButtonBackgroundColor,
  textAlign,
}) => {
  const titleParts = splitTitle(title, coloredPart ?? '');

  return (
    <StyledCard textAlign={textAlign}>
      <Title>
        {titleParts.map((part, index) =>
          part.toLowerCase() === coloredPart?.toLowerCase() ? (
            <span key={index} style={{ color: getColor('green') }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </Title>
      <StyledMarkdown
        children={paragraph ?? ''}
        options={{
          overrides: {
            a: { component: Link },
            span: { component: 'p' },
          },
        }}
      />
      {link && (
        <LinkButton
          label={label}
          variant="outline"
          href={link ?? '#'}
          icon={linkButtonIcon}
          backgroundColor={linkButtonBackgroundColor}
        ></LinkButton>
      )}
      {button && (
        <KlappirButton
          variant="primary"
          href={button.link ?? '#'}
          label={button.label}
        />
      )}
    </StyledCard>
  );
};

export default Card;
