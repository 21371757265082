import React from 'react';

import { rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

interface TableTheme {
  headerBackground?: string;
  bodyBackground?: string;
  stripedRowBackground?: string;
  borderColor?: string;
  borders?: {
    horizontal?: {
      enabled: boolean;
      showOnRows?: number[];
    };
    vertical?: {
      enabled: boolean;
      showOnColumns?: number[];
    };
  };
}

type ResponsiveTableProps = {
  head?: React.ReactNode[];
  body?: Array<React.ReactNode[]>;
  theme?: TableTheme;
} & React.HTMLAttributes<HTMLDivElement>;

const TableWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 ${rem(36)};
  width: 95%;
  max-width: ${rem(1384)};
`;

const TableContainer = styled.div<{ $theme?: TableTheme }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: ${rem(14)};
  border-bottom: ${({ $theme }) =>
    $theme?.borders?.horizontal?.enabled
      ? `1px solid ${$theme?.borderColor || '#e0e0e0'}`
      : 'none'};

  @media (max-width: ${rem(1200)}) {
    gap: ${rem(20)} 0;
  }

  > div:first-child > div:first-child {
    border-top-left-radius: ${rem(8)};
  }

  > div:last-child > div:first-child {
    border-top-right-radius: ${rem(8)};
  }
`;

const Column = styled.div<{ $columnCount: number; $index: number }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: calc(100% / ${({ $columnCount }) => $columnCount});

  > div:last-child {
    flex: 1;
  }

  @media (max-width: ${rem(1200)}) {
    width: 50%;

    // First column in each row
    &:nth-child(2n-1) {
      > div:first-child {
        border-top-left-radius: ${rem(8)};
      }
      > div {
        border-left: none !important;
      }
    }

    // Last column in each row
    &:nth-child(2n) {
      > div:first-child {
        border-top-right-radius: ${rem(8)};
      }
      > div {
        border-right: none !important;
      }
    }
  }

  @media (max-width: ${rem(768)}) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: ${rem(20)};
    }

    > div:first-child {
      border-top-left-radius: ${rem(8)};
      border-top-right-radius: ${rem(8)};
    }

    > div {
      border-left: none !important;
      border-right: none !important;
    }
  }
`;

const HeaderCell = styled.div<{ $theme?: TableTheme; $showBorder?: boolean }>`
  padding: ${rem(11)} ${rem(16)};
  min-height: ${rem(132)};
  display: flex;
  align-items: stretch;
  background: ${({ $theme }) => $theme?.headerBackground || 'transparent'};
  ${({ $theme, $showBorder }) =>
    $showBorder &&
    `border-right: 1px solid ${$theme?.borderColor || '#e0e0e0'}`};

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const BodyCell = styled.div<{
  $theme?: TableTheme;
  $showBorder?: boolean;
  $isStriped?: boolean;
  $showRowBorder?: boolean;
}>`
  padding: ${rem(11)} ${rem(16)};
  background: ${({ $theme, $isStriped }) =>
    $isStriped
      ? $theme?.stripedRowBackground || '#f9f9f9'
      : $theme?.bodyBackground || 'transparent'};
  ${({ $theme, $showBorder }) =>
    $showBorder &&
    `border-right: 1px solid ${$theme?.borderColor || '#e0e0e0'}`};
  ${({ $theme, $showRowBorder }) =>
    $showRowBorder &&
    `border-bottom: 1px solid ${$theme?.borderColor || '#e0e0e0'}`};
`;

export const ResponsiveTable: React.FC<ResponsiveTableProps> = ({
  head,
  body,
  theme,
  ...props
}) => {
  const shouldShowRowBorder = (index: number) => {
    if (!theme?.borders?.horizontal?.enabled) return false;
    if (!theme.borders.horizontal.showOnRows) return true;
    return theme.borders.horizontal.showOnRows.includes(index);
  };

  const shouldShowColumnBorder = (index: number) => {
    if (!theme?.borders?.vertical?.enabled) return false;
    if (!theme.borders.vertical.showOnColumns) return true;
    return theme.borders.vertical.showOnColumns.includes(index);
  };

  return (
    <TableWrapper>
      <TableContainer>
        {head?.map((headerContent, columnIndex) => (
          <Column
            key={columnIndex}
            $columnCount={head.length}
            $index={columnIndex}
          >
            <HeaderCell
              $theme={theme}
              $showBorder={shouldShowColumnBorder(columnIndex)}
            >
              {headerContent}
            </HeaderCell>
            {body?.map((row, rowIndex) => (
              <BodyCell
                key={rowIndex}
                $theme={theme}
                $showBorder={shouldShowColumnBorder(columnIndex)}
                $isStriped={rowIndex % 2 === 1}
                $showRowBorder={shouldShowRowBorder(rowIndex)}
              >
                {row[columnIndex]}
              </BodyCell>
            ))}
          </Column>
        ))}
      </TableContainer>
    </TableWrapper>
  );
};
