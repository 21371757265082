import React from 'react';

import { getColor, Line } from '@klappir/ui/brand';
import { Link } from '@klappir/ui/text';
import { easings, rem } from '@klappir/util/styles';
import styled, { css } from '@klappir/vendor/styled';

interface MobileNavProps {
  visible: boolean;
  children: React.ReactNode;
}

const StyledMobileNav = styled.nav<{ visible: boolean }>`
  ${({ visible }) => css`
    perspective: 2000px;

    position: fixed;
    top: 0;
    left: 0;

    margin: 0;
    padding: ${rem(130)} 0 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    z-index: 8;

    opacity: 0.2;

    transform: translate3d(0, -100vh, 0);

    transition: transform 0.1s 0.2s ${easings.easeOutCubic}, opacity 0.2s ease;

    background: ${getColor('white', 100, 99)};

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(50px);
      background: ${getColor('white', 100, 50)};
    }

    .line {
      margin: auto;
      width: 150vmax;
      position: absolute;
      top: 50%;
      left: 60%;
      z-index: -1;
      transform: translate(-50%, -50%) rotate(-60deg);
      opacity: 0;
      transition: opacity 0s ease;
    }

    [data-split],
    [data-social] > span,
    [data-scrollable] > ${Link} {
      opacity: 0;
      transform: translate3d(-10px, 0, 0);
      transition: transform 0.2s ${easings.easeOutCubic}, opacity 0.2s;
    }

    ${visible &&
    css`
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: transform 0.1s ${easings.easeOutCubic}, opacity 0.3s 0.1s ease;

      .line {
        opacity: 0.3;
        transition: opacity 0.3s 0.2s ease;
      }

      [data-scrollable] > ${Link} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.4s;
        transition-delay: 0.4s;
        /* background: blue; */

        &:nth-child(3) {
          transition-delay: 0.5s;
          /* background: green; */
        }
        &:nth-child(5) {
          transition-delay: 0.6s;
          /* background: red; */
        }
      }
      [data-split] {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.4s;
        transition-delay: 0.7s;
      }
      [data-secondary] > ${Link} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.4s;
        transition-delay: 0.8s;
        /* background: blue; */

        &:nth-child(2) {
          transition-delay: 0.9s;
          /* background: green; */
        }
        &:nth-child(3) {
          transition-delay: 1s;
          /* background: red; */
        }
        &:nth-child(4) {
          transition-delay: 1.1s;
          /* background: red; */
        }
        &:nth-child(6) {
          transition-delay: 1.2s;
          /* background: red; */
        }
      }
      [data-social] > span,
      [data-social] > ${Link} {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 0.7s ${easings.easeOutCubic}, opacity 0.4s;
        transition-delay: 1.3s;
      }
      [data-social] > ${Link} {
        transition-delay: 1.5s;

        &:nth-child(2) {
          transition-delay: 1.4s;
          /* background: green; */
        }
      }
    `}
  `}
`;

export const MobileNav: React.FC<MobileNavProps> = ({
  children,
  visible,
  ...props
}) => {
  return (
    <StyledMobileNav visible={visible}>
      {children}
      <Line
        className="line"
        offsetFromCenter={40}
        customColor={getColor('orange', 20)}
      />
    </StyledMobileNav>
  );
};
